<template>
    <div class="tableList">
    
      <div class="tableItem">
        <a-table
          rowKey="id"
          :columns="tableInfo.columns"
          :data-source="tableInfo.datas"
          :pagination="false"
          :loading="loading"
          :locale="{ emptyText: '暂无数据' }"
        >
        <template slot="status" slot-scope="status">
          <a-tag color="red" v-if="status == 0">未发布</a-tag>
          <a-tag color="green" v-if="status == 1">已发布</a-tag>
        </template>
          <span slot="action" slot-scope="action">
            <a-button
              @click="addOrUpdate(action,'show')"
              size="small"
              type="primary"
              v-if="action.status==1"
            >查看</a-button>
            <a-button
                @click="copy(action)"
                size="small"
                type="primary"
              >复制</a-button>
            
          </span>
        </a-table>
      </div>
      <a-modal
        title="流程定义"
        v-model="editor.visible"
        width="90%"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <editdefinition :info="editor.info" @callback="modelCallBack" />
      </a-modal>
    </div>
  </template>
  
  <script>
  import workflowApi from "@/api/workflow"
  const columns = [
    {
      dataIndex: "id",
      key: "id",
      align: "center",
      title: "id",
    },
    {
      dataIndex: "name",
      key: "name",
      align: "center",
      title: "名称",
    },
    {
      dataIndex: "description",
      key: "description",
      align: "center",
      title: "描述"
    },
    {
      dataIndex: "status",
      key: "status",
      align: "center",
      title: "状态",
      scopedSlots: { customRender: "status" },
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      scopedSlots: { customRender: "action" }
    },
    
  ];
  
  import editdefinition from "./editdefinition";
  export default {
    name: "workflow",
    props:{
        processModelKey:{
            type:String
        }
    },
    data() {
      return {
        loading: false,
        tableInfo: {
          datas: [],
          columns,
        },
        editor: {
          title: "编辑",
          visible: false,
          info: {},
        }
      };
    },
    components: {
      editdefinition
    },
    mounted() {
        if(this.processModelKey!=null && this.processModelKey!=""){
            this.getList();
        }
    },
    methods: {
      async getList() {
        workflowApi.getList(this.processModelKey).then((res) => {
          if (res.errorCode === this.$commons.RespCode.success) {
            this.tableInfo.datas = res.data;
          } else {
            this.$message.error(res.errorMsg);
          }
        });
      },
      remove(id){
        workflowApi.remove(id).then(res=>{
          if (res.errorCode === this.$commons.RespCode.success) {
            this.$message.success("操作成功");
            this.getList();
          } else {
            this.$message.error(res.errorMsg);
          }
        })
      },
      publish(id){
        workflowApi.publish(id).then(res=>{
          if (res.errorCode === this.$commons.RespCode.success) {
            this.$message.success("操作成功");
            this.getList();
          } else {
            this.$message.error(res.errorMsg);
          }
        })
      },
      addOrUpdate(info,action) {
        let _this = this;
        _this.editor.visible = true;
        _this.editor.info = {...info,action};
      },
      copy(info){
        let _this = this;
        _this.editor.info = {...info,action:'copy'};
        _this.editor.visible = true;

      },
      modelCallBack() {
        this.editor.visible = false;
        this.getList();
      },
      
    },
  };
  </script>
  
  <style lang="less">
  </style>